//  Legasy icon data (cruves) for Svg grafics

const nva = [
    [11, 39, 19, 14, 92, 12, 93, 49], [93, 49, 94, 49, 87, 43, 86, 44],
    [86, 44, 85, 19, 26, 16, 18, 39], [18, 39, 18, 39, 25, 39, 25, 39],
    [25, 39, 25, 39, 25, 33, 25, 33], [25, 33, 25, 33, 32, 33, 32, 33],
    [32, 33, 32, 33, 32, 39, 32, 39], [32, 39, 32, 39, 43, 39, 43, 39],
    [43, 39, 43, 39, 43, 33, 43, 33], [43, 33, 43, 33, 50, 33, 50, 33],
    [50, 33, 50, 33, 50, 39, 50, 39], [50, 39, 50, 39, 63, 39, 63, 44],
    [63, 44, 63, 44, 62, 48, 59, 48], [59, 48, 59, 48, 63, 49, 63, 49],
    [63, 49, 63, 49, 72, 33, 72, 33], [72, 33, 72, 33, 79, 33, 79, 33],
    [79, 33, 79, 33, 79, 53, 79, 53], [79, 53, 84, 54, 88, 57, 88, 62],
    [88, 62, 87, 85, 9, 84, 9, 49], [9, 49, 9, 49, 10, 45, 10, 44],
    [10, 44, 10, 44, 17, 43, 17, 43], [17, 43, 17, 43, 16, 49, 16, 49],
    [16, 49, 16, 83, 83, 79, 83, 62], [83, 62, 83, 59, 79, 57, 79, 57],
    [79, 57, 79, 57, 79, 64, 79, 64], [79, 64, 79, 64, 72, 64, 72, 64],
    [72, 64, 72, 64, 72, 54, 72, 54], [72, 54, 72, 54, 67, 53, 67, 53],
    [67, 53, 67, 53, 69, 49, 69, 49], [69, 49, 69, 49, 72, 50, 72, 50],
    [72, 50, 72, 50, 72, 44, 72, 44], [72, 44, 72, 44, 61, 64, 61, 64],
    [61, 64, 61, 64, 54, 64, 54, 64], [54, 64, 54, 64, 61, 51, 61, 51],
    [61, 51, 61, 51, 54, 49, 54, 49], [54, 49, 54, 49, 52, 46, 52, 46],
    [52, 46, 52, 46, 57, 45, 57, 44], [57, 44, 57, 43, 50, 42, 50, 42],
    [50, 42, 50, 42, 50, 64, 50, 64], [50, 64, 50, 64, 43, 64, 43, 64],
    [43, 64, 43, 64, 43, 42, 43, 42], [43, 42, 43, 42, 32, 42, 32, 42],
    [32, 42, 32, 42, 32, 64, 32, 64], [32, 64, 32, 64, 25, 64, 25, 64],
    [25, 64, 25, 64, 25, 42, 25, 42], [25, 42, 25, 42, 4, 44, 4, 47],
    [4, 47, 4, 47, 4, 40, 4, 40], [4, 40, 5, 39, 11, 39, 11, 39]
];

export const drochIcons = {
    nva:nva
}



